import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import Menu from "../components/menu"
import MenuSection from "../components/menuSection"

const Linens = () => (
  <Layout>
    <Seo title="Linens" />
    <Banner>
      <StaticImage
        src="../images/linens_banner.jpg"
        alt="Banner image showing two tables, each of them decorated for an event"
        placeholder="blurred"
        layout="fullWidth"
      />
    </Banner>
    <Menu title="Linens">
      <MenuSection title="Table Cloths">
        <ul>
          <li>Rectangle &#9702; Round &#9702; Square</li>
          <li>
            Black &#9702; White &#9702; Ivory &#9702; Beige &#9702; Gray &#9702;
            Navy &#9702; Royal &#9702; Purple &#9702; Gold
          </li>
          <li>Red &amp; White Checked &#9702; Black &amp; White Checked</li>
        </ul>
      </MenuSection>
      <MenuSection title="Additional Collections">
        <ul>
          <li>Napkins</li>
          <li>Chair Covers</li>
          <li>Chair Sashes and Ties</li>
          <li>Table Runners</li>
        </ul>
      </MenuSection>
    </Menu>
  </Layout>
)

export default Linens
